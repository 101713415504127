import(/* webpackMode: "eager" */ "/app/apps/frontend/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/apps/frontend/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/apps/frontend/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/ActivitiesApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/AuthApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/AvatarsApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/CurrenciesApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/DocumentsApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/EnquiriesApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/FormTemplateContentsApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/FormTemplatesApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/HealthApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/InviteRequestsApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/InvitesApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/LogosApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/MattersApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/MatterStepsApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/MessagesApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/NotificationsApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/OrganisationsApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/StepDocumentUploadsApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/StepFormsApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/StepsApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/StepTasksApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/TourPagesApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/UsersApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/WorkflowsApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/gen/api-hooks/WorkflowStepsApiHooks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/common/utils/upload.utils.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Documents/DocumentOverviewCard.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Documents/DocumentUploadForm.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Documents/DocumentUploadModal.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Enquiries/EnquiryCard.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Enquiries/EnquiryChatMessageBubble.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Enquiries/EnquiryChatReply.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Enquiries/EnquiryCreateCard.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Enquiries/EnquiryCreateModal.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Enquiries/EnquiryFilters.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Enquiries/EnquiryMessageCard.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Enquiries/EnquiryMessageHeader.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Enquiries/EnquiryOverviewCard.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Enquiries/EnquiryOverviewHeader.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Enquiries/EnquiryReplyCard.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Enquiries/EnquiryUpdateStatusDialog.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Errors/ErrorWithToast.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Errors/ForbiddenError.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Errors/GenericError.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Footer/FooterLinks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Invite/InviteCard.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Invite/InviteRequestCard.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Invite/RequestInviteCard.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Matters/CreateMattersForm.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Matters/MatterInviteCard.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Matters/MatterInviteRequestCard.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Matters/MatterUserInviteCard.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Members/MemberRoleSelect.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Navbar/NavAuth.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Navbar/PlatformNavLinks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Notifications/NotificationsDropdown/NotificationAlert.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Notifications/NotificationsDropdown/NotificationList.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Notifications/NotificationsDropdown/NotificationsDropdown.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Notifications/NotificationStatusSelect.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Organisations/CreateOrganisationForm.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Organisations/ManageOrganisationProfile.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Organisations/OrganisationCloseDialog.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Organisations/OrganisationInviteCard.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Organisations/OrganisationInviteRequestCard.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Organisations/OrganisationProfile.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Organisations/OrganisationUserInviteCard.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Sidebar/MatterSidebar/MatterCombobox.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Sidebar/MatterSidebar/MatterSidebarContent.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Sidebar/MobileSidebar.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Sidebar/SidebarLink.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Sidebar/SidebarSectionLinks.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Step/StepCreateModal.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Step/StepEditModal.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Step/StepFilters.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Step/StepForm.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Step/StepOverviewCard.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/Step/StepTaskForm.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/UserAvatarMenu/UserAvatarMenu.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/UserProfile/UserProfile.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/UserProfile/UserProfileEditForm.tsx");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/UserProfileContext/UserProfileContextProvider.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/aceternity/sparkles.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/DataTable/data-table-pagination.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/DataTable/data-table.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/Dropzone/Dropzone.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/HoneycombGrid/HoneycombGrid.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/Icons/Logo/Logo.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ScrollAreaPatched/scroll-area-patched.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/accordion.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/alert-dialog.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/aspect-ratio.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/avatar.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/calendar.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/checkbox.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/collapsible.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/command.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/dialog.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/dropdown-menu.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/form.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/hover-card.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/label.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/popover.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/progress.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/radio-group.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/resizable.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/scroll-area.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/select.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/separator.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/sheet.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/sonner.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/switch.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/tabs.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/tooltip.tsx")